import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Box, Divider, Grid, Stack } from "@mui/material";
import dayjs from "dayjs";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where
} from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";
import { useHttpsCallable } from "react-firebase-hooks/functions";

import SkeletonConversationBlock from "@skeletons/Conversation/SkeletonConversationBlock";
import SkeletonConversationContainerHeader from "@skeletons/Conversation/SkeletonConversationContainerHeader";
import SkeletonMessageBlock from "@skeletons/Conversation/SkeletonMessageBlock";

import ConversationBlock from "@components/Conversation/ConversationBlock";
import ConversationContainerHeader from "@components/Conversation/ConversationContainerHeader";
import MessageBlock from "@components/Conversation/MessageBlock";
import MessageInput from "@components/Conversation/MessageInput";
import MessageInputDisabled from "@components/Conversation/MessageInputDisabled";
import NoMessages from "@components/Conversation/NoMessages";
import Status from "@components/Status";
import Typography from "@components/Typography";

import useCompanyDetails from "@hooks/database/useCompanyDetails";
import useUserProfile from "@hooks/database/useUserProfile";
import { useLoadMessages } from "@hooks/useLoadMessages";
import useToast from "@hooks/useToast";

import CompanyProfile from "@interfaces/database/CompanyProfile";
import Conversation from "@interfaces/database/Conversation";
import JobProfile from "@interfaces/database/JobProfile";
import Message from "@interfaces/database/Message";
import MultiLingual from "@interfaces/database/MultiLingual";
import CandidateDetails from "@interfaces/functions/CandidateDetails";

import {
  CONVERSATION_STATUS,
  FILES_LOCATION_COMPANY_LOGO,
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_HIRING_FLOW_ALL_LABELS,
  JOB_HIRING_FLOW_INTERVIEW,
  JOB_HIRING_FLOW_OFFER,
  JOB_HIRING_FLOW_RESUME_SCREENING,
  MESSAGE_TYPE
} from "@utils/config";
import {
  fetchConversationDoc,
  isConversationExist,
  resetUnreadMessageCount,
  sendNewMessage
} from "@utils/conversation";
import fetchInitials from "@utils/fetchInitials";
import { auth, db, functions } from "@utils/firebase";
import { getFileURL } from "@utils/getFileURL";
import { getMessageTimeBanner } from "@utils/getMessageTimeBanner";
import { resolveMultiLingual } from "@utils/multiLingual";
import { isCandidateProfilePurchased } from "@utils/purchaseResume";
import theme, { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

import "dayjs/locale/en";

const EmployerConversations = () => {
  const { conversation_id: paramConversationId } = useParams();
  const conversationId = paramConversationId ?? "";
  const [conversationParams] = useSearchParams();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const userProfile = useUserProfile();
  const companyData = useCompanyDetails();
  const companyId = userProfile?.value?.company_id ?? "";
  const toast = useToast();

  const [activeConversationId, setActiveConversationId] =
    useState<string>(conversationId);
  const [activeConversationData, setActiveConversationData] =
    useState<Conversation>();
  const [applyingForJobTitle, setApplyingForJobTitle] =
    useState<MultiLingual>();

  const [activeConversationCandidateData, setActiveConversationCandidateData] =
    useState<{
      name: MultiLingual<string>;
      photo_url: string;
      job_title: MultiLingual<string>;
      status?: typeof CONVERSATION_STATUS[keyof typeof CONVERSATION_STATUS];
    }>();
  const [
    isActiveConversationCandidateDataLoading,
    setIsActiveConversationCandidateDataLoading
  ] = useState<boolean>(false);

  const [getCandidateDetails] = useHttpsCallable(
    functions,
    "getCandidateDetails"
  );
  const [showMessageBlockLoader, setShowMessageBlockLoader] =
    useState<boolean>(false);
  const applicationId = conversationParams.get("application_id") ?? "";
  const candidateId = conversationParams.get("candidate_id") ?? "";
  const jobId = conversationParams.get("job_id") ?? "";
  const isJobIdValid = companyData.value?.jobs?.includes(jobId);

  // load message history logic start
  const { loading, items, hasNextPage, error, loadMore } = useLoadMessages({
    conversationId: activeConversationId
  });

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error
  });

  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const lastScrollDistanceToBottomRef = useRef<number>();

  const reversedItems = useMemo(() => [...items].reverse(), [items]);

  // We keep the scroll position when new items are added etc.
  const scrollableRoot = scrollableRootRef.current;
  useLayoutEffect(() => {
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0;
    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
  }, [reversedItems, rootRef]);

  useEffect(() => {
    // Reset scroll position to the bottom whenever the conversation changes
    if (scrollableRoot) {
      scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
    }
  }, [activeConversationId]);

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, []);
  // load message history logic end

  const conversationCollection = collection(
    db,
    FIRESTORE_COLLECTIONS.CONVERSATIONS
  );
  const [conversationsData, conversationsDataLoading, conversationsDataError] =
    userProfile?.value?.company_id
      ? useCollection(
          query(
            conversationCollection,
            where("company_id", "==", userProfile.value?.company_id),
            orderBy("updated_at", "desc")
          )
        )
      : [undefined, false, undefined];

  const handleConversationClick = async (conversationId: string) => {
    // if the conversation id is same then ignore to call the firebase
    if (activeConversationId === conversationId) {
      return;
    }
    // update the url if user click on the specific conversation
    navigate(
      `/${translate.getCurrentLocale()}/employers/conversations/${conversationId}`
    );
    setActiveConversationId(conversationId);
  };

  const handleSendMessage = async (msg: string) => {
    setShowMessageBlockLoader(true);
    const conversationCollectionReference = collection(
      db,
      `${FIRESTORE_COLLECTIONS.CONVERSATIONS}`
    );

    // create new conversation
    if (
      activeConversationId === "new" &&
      applicationId &&
      candidateId &&
      companyId &&
      jobId
    ) {
      try {
        const jobDocRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
        const jobSnapshot = await getDoc(jobDocRef);

        if (
          jobSnapshot.exists() &&
          user?.uid &&
          userProfile?.value?.user_type
        ) {
          const jobData = jobSnapshot.data() as JobProfile;
          const companyId = jobData.company_id;
          const clientCompanyId = jobData?.client_company_id ?? "";
          const companyRef = doc(
            db,
            FIRESTORE_COLLECTIONS.COMPANIES,
            companyId
          );
          const companySnap = await getDoc(companyRef);
          let companyData = companySnap.data() as CompanyProfile;
          if (clientCompanyId) {
            const clientCompanyRef = doc(
              db,
              FIRESTORE_COLLECTIONS.COMPANIES,
              clientCompanyId
            );
            const clientCompanySnap = await getDoc(clientCompanyRef);
            if (clientCompanySnap.exists()) {
              companyData = clientCompanySnap.data() as CompanyProfile;
            }
          }

          const conversation: Conversation = {
            application_id: applicationId,
            candidate_id: candidateId,
            company_id: companyId,
            ...(clientCompanyId ? { client_company_id: clientCompanyId } : {}),
            company_unread_msg_count: {
              [user.uid]: 0
            },
            candidate_unread_msg_count: {
              [candidateId]: 0
            },
            job_id: jobId,
            updated_at: Timestamp.now(),
            created_at: Timestamp.now(),
            metadata: {
              last_message_body: "",
              candidate_photo_url:
                activeConversationCandidateData?.photo_url ?? "",
              candidate_name: activeConversationCandidateData?.name,
              last_message_by_user_id: user.uid,
              last_message_type: MESSAGE_TYPE.FROM_COMPANY,
              candidate_job_title: activeConversationCandidateData?.job_title,
              company_name: companyData?.name,
              company_photo_url: getFileURL(
                FILES_LOCATION_COMPANY_LOGO,
                clientCompanyId ? clientCompanyId : companyId,
                companyData?.logo?.extension,
                companyData?.logo?.uploaded_at
              ),
              applying_for_job_title: jobData?.job_title
            },
            status: CONVERSATION_STATUS.OK
          };
          const conversationDocument = await addDoc(
            conversationCollectionReference,
            conversation
          );
          const conversationDocId = conversationDocument.id;
          setActiveConversationId(conversationDocId);
          const message: Message = {
            body: msg,
            sender_id: companyId,
            type: MESSAGE_TYPE.FROM_COMPANY,
            created_at: Timestamp.now(),
            updated_at: Timestamp.now()
          };
          try {
            await sendNewMessage(
              conversationDocId,
              message,
              user.uid,
              userProfile?.value?.user_type
            );
            if (scrollableRoot) {
              scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
            }
            if (conversationDocId) {
              // redirect to created conversation
              navigate(
                `/${translate.getCurrentLocale()}/employers/conversations/${conversationDocId}`
              );
            }
          } catch (error) {
            toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
          }
        }
      } catch (error) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
      setShowMessageBlockLoader(false);
      return;
    }

    // update existing conversation messages
    if (
      activeConversationId &&
      activeConversationId !== "new" &&
      user?.uid &&
      userProfile?.value?.user_type
    ) {
      if (companyId) {
        const message: Message = {
          body: msg,
          sender_id: companyId,
          type: MESSAGE_TYPE.FROM_COMPANY,
          created_at: Timestamp.now(),
          updated_at: Timestamp.now()
        };

        try {
          await sendNewMessage(
            activeConversationId,
            message,
            user?.uid,
            userProfile?.value?.user_type
          );
          if (scrollableRoot) {
            scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
          }
          setShowMessageBlockLoader(false);
        } catch (error) {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      if (activeConversationId !== "new" && activeConversationId) {
        const activeConversationData = await fetchConversationDoc(
          activeConversationId
        );
        // check conversationId is valid or not
        if (activeConversationData) {
          setActiveConversationData(activeConversationData);
        } else {
          setActiveConversationId("");
          navigate(`/${translate.getCurrentLocale()}/employers/conversations`);
        }
      }

      // for new conversation, fetch job title from job collection and set
      if (activeConversationId === "new" && jobId) {
        const docRef = doc(db, FIRESTORE_COLLECTIONS.JOBS, jobId);
        const docSnap = await getDoc(docRef);
        const jobData = docSnap.data() as JobProfile;
        setApplyingForJobTitle(jobData?.job_title);
      } else {
        setApplyingForJobTitle(undefined);
      }
    })();
  }, [activeConversationId]);

  useEffect(() => {
    setActiveConversationId(conversationId);
  }, [conversationId]);

  const candidateDocId = candidateId
    ? candidateId
    : activeConversationData?.candidate_id;
  useEffect(() => {
    // fetch candidateId info using candidateId and set the name,jobTitle and profile pic
    (async () => {
      try {
        if (candidateDocId && activeConversationId === "new") {
          setIsActiveConversationCandidateDataLoading(true);
          // for new conversation fetch latest data
          const result = await getCandidateDetails({
            companyId,
            candidateIds: [candidateDocId]
          });
          const candidateDetails = result?.data as Record<
            string,
            CandidateDetails
          >;
          const singleCandidateDetail = candidateDetails[candidateDocId];
          setActiveConversationCandidateData({
            photo_url: singleCandidateDetail?.photo_url ?? "",
            name: singleCandidateDetail?.name,
            job_title: singleCandidateDetail?.current_job_title,
            status: CONVERSATION_STATUS.OK
          });
          setIsActiveConversationCandidateDataLoading(false);
        } else if (activeConversationId) {
          // for old conversation fetch candidate data from conversation metadata
          setIsActiveConversationCandidateDataLoading(true);
          const conversation = conversationsData?.docs.find(
            (singleConversationData) => {
              return singleConversationData?.id === activeConversationId;
            }
          );
          if (conversation?.exists()) {
            const conversationData = conversation.data() as Conversation;
            if (conversationData) {
              setActiveConversationCandidateData({
                photo_url: conversationData.metadata?.candidate_photo_url ?? "",
                name: conversationData.metadata?.candidate_name ?? {
                  en: "",
                  ja: ""
                },
                job_title: conversationData.metadata?.candidate_job_title ?? {
                  en: "",
                  ja: ""
                },
                status: conversationData?.status
              });
            }
          }
          setIsActiveConversationCandidateDataLoading(false);
        }
      } catch (err) {
        toast.kampai(
          intl.get("t_employer_conversation_not_valid_toast_error_message"),
          "error"
        );
        navigate("/employers/dashboard");
      }
    })();
  }, [candidateDocId, activeConversationId]);

  useEffect(() => {
    // if required id's are not found, redirect to the conversations page
    if (
      (!applicationId || !candidateId || !jobId) &&
      conversationId === "new"
    ) {
      navigate(`/${translate.getCurrentLocale()}/employers/conversations`);
      setActiveConversationId("");
      return;
    }
    // if conversation already exist then select that conversation
    (async () => {
      const isConversationAlreadyExist = await isConversationExist({
        applicationId,
        companyId,
        jobId,
        candidateId
      });
      if (isConversationAlreadyExist) {
        const conversationCollectionReference = collection(
          db,
          FIRESTORE_COLLECTIONS.CONVERSATIONS
        );
        const conversation = await getDocs(
          query(
            conversationCollectionReference,
            where("application_id", "==", applicationId),
            where("company_id", "==", companyId),
            where("candidate_id", "==", candidateId),
            where("job_id", "==", jobId)
          )
        );
        navigate(
          `/${translate.getCurrentLocale()}/employers/conversations/${
            conversation?.docs[0]?.id
          }`
        );
        setActiveConversationId(conversation?.docs[0]?.id);
      }
    })();
  }, [
    activeConversationId,
    conversationId,
    applicationId,
    companyId,
    candidateId,
    jobId
  ]);

  useEffect(() => {
    if (conversationsDataError?.message) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [conversationsDataError?.message]);

  useEffect(() => {
    if (
      activeConversationId &&
      userProfile?.value?.user_type &&
      user?.uid &&
      reversedItems?.length > 0
    ) {
      resetUnreadMessageCount(
        activeConversationId,
        userProfile.value.user_type,
        user.uid
      );
    }
  }, [activeConversationId, reversedItems]);

  useEffect(() => {
    (async () => {
      if (candidateId && user?.uid && userProfile) {
        const isCandidateProfileAlreadyPurchased =
          await isCandidateProfilePurchased(candidateId, userProfile, user.uid);
        if (
          !companyData?.loading &&
          companyData.value &&
          companyData.value.jobs &&
          companyData.value.jobs?.length > 0 &&
          (!isCandidateProfileAlreadyPurchased || !isJobIdValid)
        ) {
          toast.kampai(
            intl.get("t_employer_conversation_not_valid_toast_error_message"),
            "error"
          );
          navigate(`/${translate.getCurrentLocale()}/employers/dashboard`);
        }
      }
    })();
  }, [
    candidateId,
    user?.uid,
    companyData.value?.jobs,
    isJobIdValid,
    companyData.loading
  ]);

  return (
    <Grid
      container
      px={{ xs: 0, md: 3 }}
      height={{
        xs: conversationId ? "100vh" : "100%",
        md: "calc(100vh - 140px)"
      }}>
      <Grid
        item
        xs={12}
        md={4}
        display={{ xs: conversationId ? "none" : "block", md: "block" }}
        height="100%"
        border={{ md: `1px solid ${colorPalette.grey.base}` }}>
        <Typography variant="h4" my={2.4} mx={1.5}>
          {intl.get("t_general_messages")}
        </Typography>
        <Divider />
        <Stack
          rowGap={0.5}
          mt={{ xs: 1, md: 2 }}
          mx={{ xs: 1, md: 0 }}
          overflow="auto"
          height="calc(100% - 100px)">
          {conversationsDataLoading ? (
            <>
              {[...Array(8)].map((_, index) => {
                return <SkeletonConversationBlock key={index} />;
              })}
            </>
          ) : (
            <>
              {activeConversationId === "new" && !showMessageBlockLoader ? (
                <>
                  {isActiveConversationCandidateDataLoading ? (
                    <SkeletonConversationBlock />
                  ) : (
                    <ConversationBlock
                      isActive
                      avatarUrl={activeConversationCandidateData?.photo_url}
                      initials={fetchInitials(
                        resolveMultiLingual(
                          activeConversationCandidateData?.name
                        ) ?? ""
                      )}
                      name={resolveMultiLingual(
                        activeConversationCandidateData?.name
                      )}
                      lastMessageTime={Timestamp.now()}
                    />
                  )}
                </>
              ) : (
                false
              )}

              {conversationsData?.docs.length === 0 &&
              activeConversationId !== "new" &&
              conversationId !== "new" ? (
                // no any conversation found
                ""
              ) : (
                <>
                  {conversationsData?.docs.map((singleConversation, index) => {
                    const conversationData =
                      singleConversation.data() as Conversation;

                    return (
                      <ConversationBlock
                        isActive={
                          singleConversation.id === activeConversationId
                        }
                        key={
                          conversationData.metadata?.last_message_time?.toString() +
                          index.toString()
                        }
                        avatarUrl={
                          conversationData.metadata?.candidate_photo_url ?? ""
                        }
                        initials={fetchInitials(
                          resolveMultiLingual(
                            conversationData?.metadata?.candidate_name
                          ) ?? ""
                        )}
                        name={resolveMultiLingual(
                          conversationData?.metadata?.candidate_name
                        )}
                        lastMessage={
                          conversationData?.metadata?.last_message_body
                        }
                        lastMessageTime={
                          conversationData.metadata?.last_message_time
                        }
                        handleConversationBlockClick={() => {
                          handleConversationClick(singleConversation.id);
                        }}
                        unreadMsgCount={
                          user?.uid
                            ? conversationData?.company_unread_msg_count?.[
                                user?.uid
                              ]
                            : 0
                        }
                        showIncomingIcon={
                          user?.uid !==
                          conversationData?.metadata?.last_message_by_user_id
                        }
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
        height={{ xs: "100vh", md: "100%" }}
        border={{ md: `1px solid ${colorPalette.grey.base}` }}
        sx={{
          borderLeftWidth: 0
        }}>
        <Stack
          direction="column"
          height={{
            xs:
              conversationsData?.docs.length === 0
                ? "calc(100% - 140px)"
                : "100%",
            md: "100%"
          }}>
          {conversationsData?.docs.length === 0 &&
          activeConversationId !== "new" ? (
            <Box mx={4} height="100%">
              <NoMessages />
            </Box>
          ) : !activeConversationId ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography variant="h2">
                {intl.get("t_general_select_messages")}
              </Typography>
            </Stack>
          ) : (
            <>
              {isActiveConversationCandidateDataLoading ? (
                <SkeletonConversationContainerHeader />
              ) : (
                <ConversationContainerHeader
                  imgSrc={activeConversationCandidateData?.photo_url ?? ""}
                  initials={fetchInitials(
                    activeConversationCandidateData?.name?.en ?? ""
                  )}
                  name={
                    resolveMultiLingual(
                      activeConversationCandidateData?.name
                    ) ?? ""
                  }
                  candidateJobTitle={
                    resolveMultiLingual(
                      activeConversationCandidateData?.job_title
                    ) ?? ""
                  }
                  applyingForJobTitle={
                    resolveMultiLingual(
                      activeConversationData?.metadata?.applying_for_job_title
                    ) ??
                    resolveMultiLingual(applyingForJobTitle) ??
                    ""
                  }
                  backBtnLink={`/${translate.getCurrentLocale()}/employers/conversations`}
                />
              )}
            </>
          )}
          <Stack
            flexGrow={1}
            display={
              conversationsData?.docs.length !== 0 && activeConversationId
                ? "flex"
                : activeConversationId === "new" && // if no message then hide the display but contains the height
                  conversationsData?.docs.length === 0
                ? "hidden"
                : "none"
            }
            rowGap={1}
            px={2.5}
            overflow="auto"
            ref={rootRefSetter}
            onScroll={handleRootScroll}>
            {hasNextPage &&
            conversationsData?.docs.length !== 0 &&
            activeConversationId &&
            activeConversationId !== "new" ? (
              <Box ref={infiniteRef} height="100%">
                <Box>
                  {[...Array(8)].map((_, index) => {
                    return (
                      <Stack
                        key={index}
                        alignItems={index % 2 === 0 ? "end" : "inherit"}>
                        <SkeletonMessageBlock
                          variant={index % 2 === 0 ? "sender" : "receiver"}
                        />
                      </Stack>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              false
            )}
            {reversedItems.map((item, index) => {
              const currentMessageDate = dayjs(item.created_at.toDate());
              const previousMessageDate =
                index > 0
                  ? dayjs(reversedItems[index - 1].created_at.toDate())
                  : null;
              const hasDayChanged = !dayjs(currentMessageDate).isSame(
                previousMessageDate,
                "day"
              );
              const isMessageSender = item.type === MESSAGE_TYPE.FROM_COMPANY;
              const isNotificationMessage =
                item.type === MESSAGE_TYPE.NOTIFICATION;

              return (
                <Stack key={item.created_at.toString() + index.toString()}>
                  {hasDayChanged ? (
                    <Typography
                      my={1.5}
                      textAlign="center"
                      variant="subtitle4"
                      color="text.secondary">
                      {getMessageTimeBanner(item.created_at)}
                    </Typography>
                  ) : (
                    false
                  )}
                  <Stack
                    mb={reversedItems.length - 1 === index ? 1 : 0}
                    alignItems={
                      isNotificationMessage
                        ? "center"
                        : isMessageSender
                        ? "flex-end"
                        : "flex-start"
                    }>
                    {isNotificationMessage ? (
                      <Status
                        labelText={
                          item.body.length > 0 &&
                          item.body !==
                            JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
                            ? intl.get(
                                "t_application_status_change_notification",
                                {
                                  status: intl.get(
                                    JOB_HIRING_FLOW_ALL_LABELS[
                                      item.body as
                                        | typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING]
                                        | typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW]
                                        | typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER]
                                    ]
                                  )
                                }
                              )
                            : `${intl.get("t_general_hired")} 🎉`
                        }
                        label={
                          JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED
                        }
                        labelTextColor={theme.palette.primary.main}
                        bgColor={colorPalette.blue[20]}
                      />
                    ) : (
                      <MessageBlock
                        key={item.created_at.toString() + index.toString()}
                        body={item.body}
                        createdAt={item.created_at}
                        variant={isMessageSender ? "sender" : "receiver"}
                      />
                    )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          {activeConversationId ? (
            <>
              <Divider />
              {activeConversationData?.status === CONVERSATION_STATUS.OK ||
              conversationId === "new" ? (
                <Stack px={2.5} mt={0.5}>
                  <MessageInput
                    conversationId={conversationId}
                    handleSendMessage={handleSendMessage}
                    disabled={showMessageBlockLoader}
                  />
                </Stack>
              ) : (
                <MessageInputDisabled />
              )}
            </>
          ) : (
            false
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default EmployerConversations;
