import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from "react";

import useInfiniteScroll from "react-infinite-scroll-hook";
import { useNavigate, useParams } from "react-router-dom";

import { Box, Divider, Grid, Stack } from "@mui/material";
import dayjs from "dayjs";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollection } from "react-firebase-hooks/firestore";

import SkeletonConversationBlock from "@skeletons/Conversation/SkeletonConversationBlock";
import SkeletonConversationContainerHeader from "@skeletons/Conversation/SkeletonConversationContainerHeader";
import SkeletonMessageBlock from "@skeletons/Conversation/SkeletonMessageBlock";

import ConversationBlock from "@components/Conversation/ConversationBlock";
import ConversationContainerHeader from "@components/Conversation/ConversationContainerHeader";
import MessageBlock from "@components/Conversation/MessageBlock";
import MessageInput from "@components/Conversation/MessageInput";
import MessageInputDisabled from "@components/Conversation/MessageInputDisabled";
import NoMessages from "@components/Conversation/NoMessages";
import Status from "@components/Status";
import Typography from "@components/Typography";

import useUserProfile from "@hooks/database/useUserProfile";
import { useLoadMessages } from "@hooks/useLoadMessages";
import useToast from "@hooks/useToast";

import Conversation from "@interfaces/database/Conversation";
import Message from "@interfaces/database/Message";
import MultiLingual from "@interfaces/database/MultiLingual";

import {
  CONVERSATION_STATUS,
  FIRESTORE_COLLECTIONS,
  JOB_APPLICATION_INVITATION_ACTION_TYPE,
  JOB_HIRING_FLOW_ALL_LABELS,
  JOB_HIRING_FLOW_INTERVIEW,
  JOB_HIRING_FLOW_OFFER,
  JOB_HIRING_FLOW_RESUME_SCREENING,
  MESSAGE_TYPE
} from "@utils/config";
import {
  fetchConversationDoc,
  resetUnreadMessageCount,
  sendNewMessage
} from "@utils/conversation";
import fetchInitials from "@utils/fetchInitials";
import { auth, db } from "@utils/firebase";
import { getMessageTimeBanner } from "@utils/getMessageTimeBanner";
import { resolveMultiLingual } from "@utils/multiLingual";
import theme, { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";
import translate, { intl } from "@utils/translate";

import "dayjs/locale/en";

const CandidateConversations = () => {
  const [user] = useAuthState(auth);
  const userProfile = useUserProfile();
  const { conversation_id: paramConversationId } = useParams();
  const conversationId = paramConversationId ?? "";
  const navigate = useNavigate();

  const toast = useToast();

  const [activeConversationId, setActiveConversationId] =
    useState<string>(conversationId);

  const [activeConversationCandidateData, setActiveConversationCandidateData] =
    useState<{
      name: MultiLingual<string>;
      photoUrl: string;
      jobTitle: MultiLingual<string>;
      status?: typeof CONVERSATION_STATUS[keyof typeof CONVERSATION_STATUS];
    }>();
  const [
    isActiveConversationCandidateDataLoading,
    setIsActiveConversationCandidateDataLoading
  ] = useState<boolean>(false);

  const [showMessageBlockLoader, setShowMessageBlockLoader] =
    useState<boolean>(false);

  // load message history logic start
  const { loading, items, hasNextPage, error, loadMore } = useLoadMessages({
    conversationId: activeConversationId
  });

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error
  });

  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const lastScrollDistanceToBottomRef = useRef<number>();

  const reversedItems = useMemo(() => [...items].reverse(), [items]);

  // We keep the scroll position when new items are added etc.
  const scrollableRoot = scrollableRootRef.current;
  useLayoutEffect(() => {
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0;
    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
  }, [reversedItems, rootRef]);

  useEffect(() => {
    // Reset scroll position to the bottom whenever the conversation changes
    if (scrollableRoot) {
      scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
    }
  }, [activeConversationId]);

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;
    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, []);
  // load message history logic end

  const conversationCollection = collection(
    db,
    FIRESTORE_COLLECTIONS.CONVERSATIONS
  );
  const [conversationsData, conversationsDataLoading, conversationsDataError] =
    user?.uid
      ? useCollection(
          query(
            conversationCollection,
            where("candidate_id", "==", user?.uid),
            orderBy("updated_at", "desc")
          )
        )
      : [undefined, false, undefined];

  const handleConversationClick = async (conversationId: string) => {
    // if the conversation id is same then ignore to call the firebase
    if (activeConversationId === conversationId) {
      return;
    }
    // update the url if user click on the specific conversation
    navigate(
      `/${translate.getCurrentLocale()}/conversations/${conversationId}`
    );
    setActiveConversationId(conversationId);
  };

  const handleSendMessage = async (msg: string) => {
    setShowMessageBlockLoader(true);
    // create new conversation
    if (
      activeConversationId &&
      conversationId &&
      user?.uid &&
      userProfile.value?.user_type
    ) {
      try {
        const message: Message = {
          body: msg,
          sender_id: user?.uid ?? "",
          type: MESSAGE_TYPE.FROM_CANDIDATE,
          created_at: Timestamp.now(),
          updated_at: Timestamp.now()
        };
        try {
          await sendNewMessage(
            conversationId,
            message,
            user?.uid,
            userProfile?.value?.user_type
          );
          if (scrollableRoot) {
            scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
          }
        } catch (error) {
          toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
        }
      } catch (error) {
        toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
      }
      setShowMessageBlockLoader(false);
      return;
    }
  };

  useEffect(() => {
    (async () => {
      if (activeConversationId) {
        const activeConversationData = await fetchConversationDoc(
          activeConversationId
        );
        // check conversationId is valid or not
        if (!activeConversationData) {
          setActiveConversationId("");
          navigate(`/${translate.getCurrentLocale()}/conversations`);
        }
      }
    })();
  }, [activeConversationId]);

  useEffect(() => {
    setActiveConversationId(conversationId);
  }, [conversationId]);

  useEffect(() => {
    (async () => {
      if (activeConversationId) {
        setIsActiveConversationCandidateDataLoading(true);
        const conversation = conversationsData?.docs.find(
          (singleConversationData) => {
            return singleConversationData?.id === activeConversationId;
          }
        );
        if (conversation?.exists()) {
          const conversationData = conversation.data() as Conversation;
          if (conversationData) {
            setActiveConversationCandidateData({
              photoUrl: conversationData.metadata?.company_photo_url ?? "",
              name: conversationData.metadata?.company_name ?? {
                en: "",
                ja: ""
              },
              jobTitle: conversationData.metadata?.applying_for_job_title ?? {
                en: "",
                ja: ""
              },
              status: conversationData?.status
            });
          }
        }
        setIsActiveConversationCandidateDataLoading(false);
      }
    })();
  }, [activeConversationId, conversationId, conversationsData]);

  useEffect(() => {
    if (conversationsDataError?.message) {
      toast.kampai(intl.get("t_toast_error_something_wrong"), "error");
    }
  }, [conversationsDataError?.message]);

  useEffect(() => {
    if (
      activeConversationId &&
      userProfile?.value?.user_type &&
      user?.uid &&
      reversedItems?.length > 0
    ) {
      resetUnreadMessageCount(
        activeConversationId,
        userProfile.value.user_type,
        user?.uid
      );
    }
  }, [activeConversationId, reversedItems]);

  return (
    <Grid
      container
      px={{ xs: 0, md: 3 }}
      height={{
        xs: conversationId ? "100vh" : "100%",
        md: "calc(100vh - 140px)"
      }}>
      <Grid
        item
        xs={12}
        md={4}
        display={{ xs: conversationId ? "none" : "block", md: "block" }}
        height="100%"
        border={{ md: `1px solid ${colorPalette.grey.base}` }}>
        <Typography variant="h4" my={2.4} mx={1.5}>
          {intl.get("t_general_messages")}
        </Typography>
        <Divider />
        <Stack
          rowGap={0.5}
          mt={{ xs: 1, md: 2 }}
          mx={{ xs: 1, md: 0 }}
          overflow="auto"
          height="calc(100% - 100px)">
          {conversationsDataLoading ? (
            <>
              {[...Array(8)].map((_, index) => {
                return (
                  <SkeletonConversationBlock
                    key={index}
                    avatarVariant="rounded"
                  />
                );
              })}
            </>
          ) : (
            <>
              {conversationsData?.docs.length === 0 ? (
                // no any conversation found
                ""
              ) : (
                <>
                  {conversationsData?.docs.map((singleConversation, index) => {
                    const conversationData =
                      singleConversation.data() as Conversation;

                    return (
                      <ConversationBlock
                        isActive={
                          singleConversation.id === activeConversationId
                        }
                        key={
                          conversationData.metadata?.last_message_time?.toString() +
                          index.toString()
                        }
                        avatarVariant="rounded"
                        avatarUrl={
                          conversationData.metadata?.company_photo_url ?? ""
                        }
                        initials={fetchInitials(
                          resolveMultiLingual(
                            conversationData?.metadata?.company_name
                          ) ?? ""
                        )}
                        name={resolveMultiLingual(
                          conversationData?.metadata?.company_name
                        )}
                        lastMessage={
                          conversationData?.metadata?.last_message_body
                        }
                        lastMessageTime={
                          conversationData.metadata?.last_message_time
                        }
                        handleConversationBlockClick={() => {
                          handleConversationClick(singleConversation.id);
                        }}
                        unreadMsgCount={
                          user?.uid
                            ? conversationData?.candidate_unread_msg_count?.[
                                user?.uid
                              ]
                            : 0
                        }
                        showIncomingIcon={
                          user?.uid !==
                          conversationData?.metadata?.last_message_by_user_id
                        }
                      />
                    );
                  })}
                </>
              )}
            </>
          )}
        </Stack>
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
        height={{ xs: "100vh", md: "100%" }}
        border={{ md: `1px solid ${colorPalette.grey.base}` }}
        sx={{
          borderLeftWidth: 0
        }}>
        <Stack
          direction="column"
          height={{
            xs:
              conversationsData?.docs.length === 0
                ? "calc(100% - 140px)"
                : "100%",
            md: "100%"
          }}>
          {conversationsData?.docs.length === 0 ? (
            <Box mx={4} height="100%">
              <NoMessages variant="candidate-conversation" />
            </Box>
          ) : !activeConversationId ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography variant="h2">
                {intl.get("t_general_select_messages")}
              </Typography>
            </Stack>
          ) : (
            <>
              {isActiveConversationCandidateDataLoading ? (
                <SkeletonConversationContainerHeader avatarVariant="rounded" />
              ) : (
                <ConversationContainerHeader
                  applyingForJobTitle={
                    resolveMultiLingual(
                      activeConversationCandidateData?.jobTitle
                    ) ?? ""
                  }
                  avatarVariant="rounded"
                  variant="candidate-conversation-header"
                  imgSrc={activeConversationCandidateData?.photoUrl ?? ""}
                  initials={fetchInitials(
                    activeConversationCandidateData?.name?.en ?? ""
                  )}
                  name={
                    resolveMultiLingual(
                      activeConversationCandidateData?.name
                    ) ?? ""
                  }
                  backBtnLink={`/${translate.getCurrentLocale()}/conversations`}
                />
              )}
            </>
          )}
          <Stack
            flexGrow={1}
            display={
              conversationsData?.docs.length !== 0 && activeConversationId
                ? "flex"
                : // if no message then hide the display but contains the height
                conversationsData?.docs.length === 0
                ? "hidden"
                : "none"
            }
            rowGap={1}
            px={2.5}
            overflow="auto"
            ref={rootRefSetter}
            onScroll={handleRootScroll}>
            {hasNextPage &&
            conversationsData?.docs.length !== 0 &&
            activeConversationId ? (
              <Box ref={infiniteRef} height="100%">
                <Box>
                  {[...Array(8)].map((_, index) => {
                    return (
                      <Stack
                        key={index}
                        alignItems={index % 2 === 0 ? "end" : "inherit"}>
                        <SkeletonMessageBlock
                          variant={index % 2 === 0 ? "sender" : "receiver"}
                        />
                      </Stack>
                    );
                  })}
                </Box>
              </Box>
            ) : (
              false
            )}
            {reversedItems.map((item, index) => {
              const currentMessageDate = dayjs(item.created_at.toDate());
              const previousMessageDate =
                index > 0
                  ? dayjs(reversedItems[index - 1].created_at.toDate())
                  : null;
              const hasDayChanged = !dayjs(currentMessageDate).isSame(
                previousMessageDate,
                "day"
              );
              const isMessageSender = item.type === MESSAGE_TYPE.FROM_CANDIDATE;
              const isNotificationMessage =
                item.type === MESSAGE_TYPE.NOTIFICATION;
              return (
                <Stack key={item.created_at.toString() + index.toString()}>
                  {hasDayChanged ? (
                    <Typography
                      my={1.5}
                      textAlign="center"
                      variant="subtitle4"
                      color="text.secondary">
                      {getMessageTimeBanner(item.created_at)}
                    </Typography>
                  ) : (
                    false
                  )}
                  <Stack
                    mb={reversedItems.length - 1 === index ? 1 : 0}
                    alignItems={
                      isNotificationMessage
                        ? "center"
                        : isMessageSender
                        ? "flex-end"
                        : "flex-start"
                    }>
                    {isNotificationMessage ? (
                      <Status
                        labelText={
                          item.body.length > 0 &&
                          item.body !==
                            JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRED
                            ? intl.get(
                                "t_application_status_change_notification",
                                {
                                  status: intl.get(
                                    JOB_HIRING_FLOW_ALL_LABELS[
                                      item.body as
                                        | typeof JOB_HIRING_FLOW_RESUME_SCREENING[keyof typeof JOB_HIRING_FLOW_RESUME_SCREENING]
                                        | typeof JOB_HIRING_FLOW_INTERVIEW[keyof typeof JOB_HIRING_FLOW_INTERVIEW]
                                        | typeof JOB_HIRING_FLOW_OFFER[keyof typeof JOB_HIRING_FLOW_OFFER]
                                    ]
                                  )
                                }
                              )
                            : `${intl.get("t_general_hired")} 🎉`
                        }
                        label={
                          JOB_APPLICATION_INVITATION_ACTION_TYPE.HIRING_FLOW_STEP_CHANGED
                        }
                        labelTextColor={theme.palette.primary.main}
                        bgColor={colorPalette.blue[20]}
                      />
                    ) : (
                      <MessageBlock
                        key={item.created_at.toString() + index.toString()}
                        body={item.body}
                        createdAt={item.created_at}
                        variant={isMessageSender ? "sender" : "receiver"}
                      />
                    )}
                  </Stack>
                </Stack>
              );
            })}
          </Stack>
          {activeConversationId ? (
            <>
              <Divider />
              {activeConversationCandidateData?.status ===
              CONVERSATION_STATUS.OK ? (
                <Stack px={2.5} mt={0.5}>
                  <MessageInput
                    conversationId={conversationId}
                    handleSendMessage={handleSendMessage}
                    disabled={showMessageBlockLoader}
                  />
                </Stack>
              ) : (
                <MessageInputDisabled />
              )}
            </>
          ) : (
            false
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default CandidateConversations;
