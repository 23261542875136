import { Reply as ReplyIcon } from "@mui/icons-material";
import { Badge, Stack, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import Avatar from "@components/Avatar";
import EllipsisTypography from "@components/EllipsisTypography";

import { UNREAD_MESSAGE_COUNT_BADGE_MAX_LIMIT } from "@utils/config";
import { formatConversationTimestamp } from "@utils/formatConversationTimestamp";
import theme, { colorPalette } from "@utils/theme";
import Timestamp from "@utils/Timestamp";

dayjs.extend(relativeTime);

interface ConversationBlockProps {
  avatarUrl?: string;
  avatarVariant?: "circular" | "rounded";
  initials?: string;
  name?: string;
  lastMessage?: string;
  unreadMsgCount?: number;
  isActive?: boolean;
  handleConversationBlockClick?: () => void;
  lastMessageTime?: Timestamp;
  showIncomingIcon?: boolean;
}

const ConversationBlock = ({
  avatarUrl = "",
  avatarVariant = "circular",
  initials = "",
  name = "",
  lastMessage = "",
  unreadMsgCount = 0,
  isActive = false,
  handleConversationBlockClick,
  lastMessageTime,
  showIncomingIcon = false
}: ConversationBlockProps) => {
  return (
    <Stack
      bgcolor={isActive ? colorPalette.blue["20"] : "inherit"}
      p={0.75}
      direction="row"
      sx={{
        "cursor": "pointer",
        "&:hover": {
          backgroundColor: colorPalette.blue["04"]
        }
      }}
      gap={1}
      onClick={handleConversationBlockClick}>
      <Avatar imgSrc={avatarUrl} initials={initials} variant={avatarVariant} />
      <Stack alignSelf="center" width="100%">
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <EllipsisTypography
            variant="subtitle5"
            lineClamp={1}
            color="text.primary">
            {name}
          </EllipsisTypography>
          {lastMessageTime ? (
            <Tooltip
              title={dayjs(lastMessageTime?.toDate()).format(
                "ddd MMM D YYYY HH:mm:ss [GMT]Z (Z)"
              )}>
              <span>
                <Typography variant="inputTable" color="text.primary" ml={1.5}>
                  {formatConversationTimestamp(lastMessageTime)}
                </Typography>
              </span>
            </Tooltip>
          ) : (
            false
          )}
        </Stack>

        <Stack
          mt={0.5}
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Stack direction="row" gap={0.5}>
            {showIncomingIcon ? (
              <ReplyIcon
                fontSize="small"
                htmlColor={theme.palette.text.secondary}
              />
            ) : (
              false
            )}
            <EllipsisTypography
              lineClamp={1}
              variant="body2"
              color={theme.palette.text.secondary}>
              {lastMessage}
            </EllipsisTypography>
          </Stack>
          {unreadMsgCount > 0 ? (
            <Badge
              badgeContent={unreadMsgCount}
              color="secondary"
              sx={{ mx: 1.5 }}
              max={UNREAD_MESSAGE_COUNT_BADGE_MAX_LIMIT}
            />
          ) : (
            false
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ConversationBlock;
